import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <Layout pageTitle="About">
      <p>
        I spend way too much time thinking about code, software, and technology.
        This is my personal outlet where I share technical details.
        Opinions are my own.
        </p>
    </Layout>
  )
}

export default AboutPage